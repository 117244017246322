import React, { useEffect, useState } from 'react'
import { liff } from '@line/liff'

import { BrowserRouter } from 'react-router-dom'

import { AppContext, dError, isDev, post, useAppStateContext } from 'unno-comutils'
import { ToastContainer } from 'unno-comutils/Dialog'

import 'unno-comutils/style/core.css'
import './style/app.scss'

import Layout from './layout'
import Router from './router'

import './service/i18n'
import { Wait } from 'unno-comutils/ui'
import L from '@loadable/component'
import { User } from 'unno-comutils/utils'

const fallback = <Wait icon={'fan'} className={'blue'}/>
const Regis = L(() => import(/* webpackChunkName: "login" */'./pages/regis'), { fallback })

export default function App () {
    const [wait, setWait] = useState(true)
    const appState = useAppStateContext()

    const [page, setPage] = useState('')

    const [profileUid, setProfileUid] = useState('')
    const [profile, setProfile] = useState<any>(null)

    const loadUser = (uid: string) => {
        setWait(true)
        post('connect/liff/check', { uid }).then(d => {
            if (d.ok) {
                appState.setUser(new User(d.user))
            }
            else {
                appState.setUser(null)
                setPage('regis')
            }
        }).finally(() => setWait(false))
    }

    function onRegis (uid: string, user: any) {
        loadUser(uid)
    }

    useEffect(() => {
        if (isDev) {
            const test_uid = 'TEST_UID'
            setProfileUid(test_uid)
            loadUser(test_uid)
        }
        else {
            liff.init({ liffId: '2006442497-GDW4VgOp' })
                .then(() => {
                    liff.getProfile()
                        .then((profile) => {
                            setProfile(profile)
                            setProfileUid(profile.userId)
                            loadUser(profile.userId)
                        })
                        .catch(() => {
                            dError('GET_PROFILE_ERROR')
                        })
                })
                .catch(() => {
                    dError('LIFF_INIT_ERROR')
                })
        }
    }, [])

    if (wait) return <Wait className="app-wait"/>

    return <AppContext.Provider value={appState}>
        <div className="layout">
            {(() => {
                if (page === 'regis') return <Regis uid={profileUid} profile={profile} onSave={(user: any) => onRegis(profileUid, user)}/>
                if (!!appState.user) return <BrowserRouter><Layout><Router/></Layout></BrowserRouter>
                return <PageEmpty/>
            })()}
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}

function PageEmpty () {
    return <div className="layout">PageEmpty</div>
}