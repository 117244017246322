import React from 'react'
import L from '@loadable/component'

import { Redirect, Route, Switch } from 'react-router-dom'

import Wait from 'unno-comutils/Wait'
import { RoutePrivate } from 'unno-comutils/Route'

const fallback = <Wait icon={'fan'} className={'blue'}/>

const Home = L(() => import(/* webpackChunkName: "home" */'./pages/home'), { fallback })

// --------------

export default function Router () {
    return <Switch>
        <Route exact path="/"><Redirect to="/home"/></Route>
        <RoutePrivate exact path="/home" children={<Home/>}/>
    </Switch>
}

//const RouterReseller = L(() => import(/* webpackChunkName: "reseller" */'./~reseller/main'), { fallback })
//<RoutePrivate needRole={'__reseller'} path="/reseller" children={<RouterReseller/>}/>
